import { RouterLinkActive, RouterModule } from '@angular/router';

import { Component } from '@angular/core';

@Component({
  selector: 'dc-sidebar-navigation',
  standalone: true,
  imports: [RouterLinkActive, RouterModule],
  templateUrl: './sidebar-navigation.component.html',
  styleUrl: './sidebar-navigation.component.scss',
})
export class SidebarNavigationComponent {}
