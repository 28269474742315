<nav class="navbar navbar-expand-lg">
  <a class="navbar-brand" routerlink="">NTRX Data Connect</a>
  <button
    class="navbar-toggler"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#navbarNavAltMarkup"
    aria-controls="navbarNavAltMarkup"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
    <ul class="navbar-nav">
      <!-- Document management -->
      <li class="nav-item">
        <a class="nav-link" routerLink="/incoming-data" routerLinkActive="active">Dokumenteneingang</a>
      </li>
      <!-- Core data -->
      <li class="nav-item">
        <a class="nav-link" routerLink="/core-data" routerLinkActive="active">Datenimport</a>
      </li>
    </ul>
    <!-- Current user -->
    <dc-account [currentUser]="currentUser | async" />
  </div>
</nav>
