import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable, map } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';

import { HttpClient } from '@angular/common/http';

@Pipe({
  name: 'secure',
  standalone: true,
})
export class SecurePipe implements PipeTransform {
  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  transform(url: string): Observable<SafeResourceUrl> {
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      map(val => {
        return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(val));
      })
    );
  }
}
