import { AsyncPipe, ViewportScroller } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbPagination, NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';

import { LoaderDirective } from '@directives/index';
import { PaginatedDataSource } from '@ntrx/pagination';
import { ngbPaginationConfig } from './configurations/ngb-pagination-config';

@Component({
  selector: 'dc-pagination',
  standalone: true,
  imports: [NgbPagination, AsyncPipe, LoaderDirective],
  providers: [{ provide: NgbPaginationConfig, useValue: ngbPaginationConfig }],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss',
})
export class PaginationComponent {
  @Input({ required: true }) public dataSource!: PaginatedDataSource<any, any>; // eslint-disable-line

  constructor(private viewportScroller: ViewportScroller) {}

  protected onPageChange(page: number): void {
    this.dataSource?.fetch(page);
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}
