import { Field } from './field.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { InputType } from '@core/types';

export class InputField<T> extends Field<T> {
  public convertToFormlyFieldConfig(inputType: InputType): FormlyFieldConfig {
    return {
      key: this.key,
      type: 'input',
      props: {
        label: this.label,
        type: inputType,
        required: this.required,
        disabled: this.disabled,
      },
    };
  }

  public getModelData(): Record<string, T | undefined> {
    return { [this.key]: this.value ?? this.recognized_value };
  }
}
