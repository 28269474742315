<!-- Data -->
<div class="mb-4" id="paginated-data" [dcLoader]="dataSource.loading$">
  <ng-content></ng-content>
</div>

<!-- Pagination -->
@if (dataSource.page$ | async; as page) {
  <div class="d-flex justify-content-center pagination-container">
    <ngb-pagination
      [pageSize]="page.meta.per_page"
      [collectionSize]="page.meta.total"
      [page]="page.meta.current_page"
      (pageChange)="onPageChange($event)"
    ></ngb-pagination>
  </div>
}
