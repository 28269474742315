import { EnvironmentConfiguration } from './environment-configuration';

export const environment: EnvironmentConfiguration = {
  production: false,
  apiBaseURL: 'https://dev.api.dataconnect.netrocks.de',
  apiVersion: 'api/v1',
  oauth: {
    authHost: 'https://dev.api.dataconnect.netrocks.de',
    callbackURL: '',
    clientId: 'webapp_dev',
    scopes: '',
    requireHttps: false,
  },
  sentry: {
    dsn: '',
    environment: 'dev',
    version: 'development',
  },
};
