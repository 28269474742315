import { DestroyRef, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Observable, finalize } from 'rxjs';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[dcLoader]',
  standalone: true,
})
export class LoaderDirective implements OnInit {
  /** Boolean Observable value indicating whether the loading spinner should be displayed or not. */
  @Input() public dcLoader: Observable<boolean> | undefined;

  /** Loader element */
  private loaderElement: HTMLDivElement | null = null;

  constructor(
    private el: ElementRef<HTMLButtonElement>,
    private renderer: Renderer2,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    if (!this.dcLoader) {
      return;
    }

    if (!this.loaderElement) {
      this.render();
    }

    this.dcLoader
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => this.hideLoader())
      )
      .subscribe({
        next: loading => {
          if (loading) {
            this.showLoader();
          } else {
            this.hideLoader();
          }
        },
      });
  }

  private render(): void {
    // Create loader wrapper
    const loaderWrapper = this.renderer.createElement('div') as HTMLDivElement;
    this.renderer.addClass(loaderWrapper, 'loader-overlay');
    // Create loader element
    const loaderElement = this.renderer.createElement('div') as HTMLDivElement;
    this.renderer.addClass(loaderElement, 'spinner-border');
    this.renderer.addClass(loaderElement, 'text-primary');
    this.renderer.setAttribute(loaderElement, 'role', 'status');
    // Create loader, set classes and attributes
    const loader = this.renderer.createElement('span') as HTMLSpanElement;
    this.renderer.addClass(loader, 'visually-hidden');
    const loadingText = this.renderer.createText($localize`Lade...`);

    this.renderer.appendChild(loader, loadingText);
    this.renderer.appendChild(loaderElement, loader);
    this.renderer.appendChild(loaderWrapper, loaderElement);

    this.loaderElement = loaderWrapper;
  }

  /**
   * Show loader
   */
  private showLoader(): void {
    if (!this.el.nativeElement) {
      return;
    }

    this.renderer.addClass(this.el.nativeElement, 'loader-parent');
    this.renderer.appendChild(this.el.nativeElement, this.loaderElement);
  }

  /**
   * Hide loader
   */
  private hideLoader(): void {
    if (!this.el.nativeElement) {
      return;
    }

    this.renderer.removeClass(this.el.nativeElement, 'loader-parent');
    this.renderer.removeChild(this.el.nativeElement, this.loaderElement);
  }
}
