import { FieldType, InputType, SelectOption, UUID } from '@core/types';

import { FieldGroupResponse } from '../form-group/field-group.model';
import { FormlyFieldConfig } from '@ngx-formly/core';

export interface FieldResponse<T = string | number | boolean | null> {
  id: UUID;
  priority: number;
  key: string;
  label: string;
  type: FieldType;
  required: boolean;
  disabled: boolean;
  form_group?: Partial<FieldGroupResponse>;
  options?: SelectOption[];
  tooltip?: string;
  recognized_value?: T;
  value?: T;
}

export type FieldRequest = Pick<FieldResponse, 'id' | 'value'>;

export abstract class Field<T = string | number | boolean | null> {
  id: UUID;
  priority: number;
  key: string;
  label: string;
  type: FieldType;
  required: boolean;
  disabled: boolean;
  form_group?: Partial<FieldGroupResponse>;
  options?: SelectOption[];
  tooltip?: string;
  recognized_value?: T;
  value?: T;

  constructor(field: FieldResponse<T>) {
    this.id = field.id;
    this.priority = field.priority;
    this.key = field.key;
    this.label = field.label;
    this.type = field.type;
    this.required = field.required;
    this.disabled = field.disabled;
    this.form_group = field.form_group;
    this.options = field.options;
    this.tooltip = field.tooltip;
    this.recognized_value = field.recognized_value;
    this.value = field.value;
  }

  /**
   * Returns the model data for the corresponding dynamic generated formly input.
   * @returns Record of the model data: Key is the `key` of the field and the value is the `value` or `recognized_value` of the field.
   */
  abstract getModelData(): Record<string, T | undefined>;
  /**
   * Converts the field properties into a valid formly config for the corresponding form control.
   * @param inputType Type of the input determines the generated form control type of an `InputField`, e.g. `text`, `email`, `date`.
   * @returns A `FormlyFieldConfig` object.
   */
  abstract convertToFormlyFieldConfig(inputType?: InputType): FormlyFieldConfig;
}
