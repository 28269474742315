import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dc-error',
  standalone: true,
  imports: [],
  templateUrl: './error.modal.html',
  styleUrl: './error.modal.scss',
})
export class ErrorModal {
  @Input({ required: true }) public title = '';
  @Input({ required: true }) public message = '';
  @Input() public details: string[] | undefined;
  @Input() public buttonText = 'Ok';

  constructor(private activeModal: NgbActiveModal) {}

  protected confirm(): void {
    this.activeModal.close(true);
  }
}
