import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';

export const ngbPaginationConfig: NgbPaginationConfig = {
  disabled: false,
  boundaryLinks: false,
  directionLinks: true,
  ellipses: false,
  maxSize: 5,
  pageSize: 50,
  rotate: true,
  size: 'md',
};
