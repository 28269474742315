import { CheckboxField, Field, FieldResponse, InputField, SelectField } from '../field';
import { InputType, UUID } from '@core/types';

import { FormlyFieldConfig } from '@ngx-formly/core';

export interface FieldGroupResponse {
  id: UUID;
  priority: number;
  name: string;
  fields: FieldResponse[];
}

/**
 * Groups the available fields into a `FieldGroup` in the generated formly form.
 */
export class FieldGroup {
  id: UUID;
  priority: number;
  name: string;
  fields: Field[];

  constructor(fieldGroup: FieldGroupResponse) {
    this.id = fieldGroup.id;
    this.priority = fieldGroup.priority;
    this.name = fieldGroup.name;
    this.fields = this.createFieldsFromResponse(fieldGroup.fields);
  }

  /**
   * Creates field instances of the given field responses.
   * @param fields Field responses to create the instances from.
   * @returns Array of instantiated fields.
   */
  private createFieldsFromResponse(fields: FieldResponse[]): Field[] {
    return fields.map(field => {
      if (field.type === 'select') {
        return new SelectField(field);
      }
      if (field.type === 'checkbox') {
        return new CheckboxField(field);
      }
      return new InputField(field);
    });
  }

  /**
   * Creates the formly field config for the field group.
   * The field config consists of a template with the field group `name` and the `fieldGroup`.
   * @returns Array of the formly field configs.
   */
  public convertToFormlyFieldConfig(): FormlyFieldConfig[] {
    return [
      {
        template: `<h6>${this.name}</h6>`,
      },
      {
        fieldGroup: this.fields.map(field => field.convertToFormlyFieldConfig(field.type as InputType)),
      },
    ];
  }
}
