import { BehaviorSubject, combineLatest, defer, from, throwError, catchError as catchError$1, map as map$1, concatMap, concat } from 'rxjs';
import { take, distinctUntilChanged, map, tap, catchError, filter } from 'rxjs/operators';
import * as i0 from '@angular/core';
import { Injectable, Optional, makeEnvironmentProviders, APP_INITIALIZER } from '@angular/core';
import * as i1 from 'angular-oauth2-oidc';
import { OAuthStorage, provideOAuthClient, OAuthResourceServerErrorHandler, AuthConfig } from 'angular-oauth2-oidc';
import * as i2 from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

/**
 * AuthService for handling authentication and refreshing of tokens.
 *
 * The main logic of the auth service is [based on this sample implementation](https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/).
 * The logic for the refresh token and the interceptor is based on the [code of this repository](https://github.com/alexzuza/angular-refresh-token).
 */
class AuthService {
  constructor(oauthService, router, config) {
    this.oauthService = oauthService;
    this.router = router;
    this.config = config;
    this.tokenSubject$ = new BehaviorSubject(null);
    /** Observable with access token */
    this.token$ = this.tokenSubject$.pipe(take(1));
    this.isAuthenticatedSubject$ = new BehaviorSubject(false);
    /** Observable with the authenticated status */
    this.isAuthenticated$ = this.isAuthenticatedSubject$.asObservable().pipe(distinctUntilChanged());
    this.isDoneLoadingSubject$ = new BehaviorSubject(false);
    /** Observable that indicates when the initial loading is done */
    this.isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();
    /**
     * Publishes `true` if and only if (a) all the asynchronous initial
     * login calls have completed or errorred, and (b) the user ended up
     * being authenticated.
     *
     * In essence, it combines:
     *
     * - the latest known state of whether the user is authorized
     * - whether the ajax calls for initial log in have all been done
     */
    this.canActivateProtectedRoutes$ = combineLatest([this.isAuthenticated$, this.isDoneLoading$]).pipe(map(values => values.every(b => b)));
    /**
     * Refreshes the access token
     */
    this.refreshToken$ = defer(() => {
      if (this.tokenSubject$.value === null) {
        return this.token$;
      }
      // Defer allows us to easily execute some action when the Observable
      // is subscribed. Here, we set the current token to `null` until the
      // refresh operation is complete. This ensures no requests will be
      // sent with a known bad token.
      this.tokenSubject$.next(null);
      return from(this.oauthService.refreshToken()).pipe(tap(res => {
        this.tokenSubject$.next(res.access_token);
      }), catchError(err => {
        this.logout();
        throw err;
      }));
    });
    this.oauthService.events.subscribe(() => {
      const hasValidAccessToken = this.oauthService.hasValidAccessToken();
      this.isAuthenticatedSubject$.next(hasValidAccessToken);
      this.tokenSubject$.next(hasValidAccessToken ? this.oauthService.getAccessToken() : null);
    });
    // Start login flow, when session is terminated or a session error occured
    this.oauthService.events.pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type))).subscribe(() => {
      this.login();
    });
    this.oauthService.setupAutomaticSilentRefresh();
  }
  /**
   * Loads the oauth configuration
   * @returns Promise, which resolves when configured
   */
  loadWithoutDiscoveryDocument() {
    this.oauthService.configure(this.config);
    return Promise.resolve();
  }
  runInitialLoginSequence() {
    return this.loadWithoutDiscoveryDocument().then(() => this.oauthService.tryLogin()).then(() => {
      this.isDoneLoadingSubject$.next(true);
      // Check for state and redirect user when authenticated
      if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
        let stateUrl = this.oauthService.state;
        if (stateUrl.startsWith('/') === false) {
          stateUrl = decodeURIComponent(stateUrl);
        }
        this.router.navigateByUrl(stateUrl);
      }
    }).catch(() => this.isDoneLoadingSubject$.next(true));
  }
  /**
   * Redirects the user to the login
   * @param targetUrl Target URL the user wants to access
   */
  login(targetUrl) {
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }
  /**
   * Logs the current user out.
   *
   * Revokes the token if a `revocationEndpoint` is configured.
   */
  logout() {
    if (this.config.revocationEndpoint) {
      this.oauthService.revokeTokenAndLogout();
      return;
    }
    this.oauthService.logOut();
  }
  static {
    this.ɵfac = function AuthService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AuthService)(i0.ɵɵinject(i1.OAuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i1.AuthConfig));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AuthService,
      factory: AuthService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.OAuthService
  }, {
    type: i2.Router
  }, {
    type: i1.AuthConfig
  }], null);
})();
class AuthGuard {
  constructor(authService) {
    this.authService = authService;
  }
  canActivateChild(route, state) {
    return this.authService.canActivateProtectedRoutes$.pipe(tap(isAuthenticated => isAuthenticated || this.authService.login(state.url)));
  }
  canActivate(route, state) {
    return this.authService.canActivateProtectedRoutes$.pipe(tap(isAuthenticated => isAuthenticated || this.authService.login(state.url)));
  }
  static {
    this.ɵfac = function AuthGuard_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AuthGuard)(i0.ɵɵinject(AuthService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AuthGuard,
      factory: AuthGuard.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthGuard, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AuthService
  }], null);
})();
class AuthErrorHandler {
  constructor(oauthService) {
    this.oauthService = oauthService;
  }
  handleError(err) {
    if (err.status === 401) {
      this.oauthService.login();
    }
    return throwError(() => err);
  }
}
class AuthInterceptor {
  constructor(authService, errorHandler, moduleConfig) {
    this.authService = authService;
    this.errorHandler = errorHandler;
    this.moduleConfig = moduleConfig;
  }
  /**
   * Check if the given url is a url that should be intercepted.
   * @param url URL to check.
   * @returns True, if it should be intercepted, otherwise false.
   */
  isInterceptedUrl(url) {
    if (this.moduleConfig?.resourceServer?.customUrlValidation) {
      return this.moduleConfig.resourceServer.customUrlValidation(url);
    }
    if (this.moduleConfig?.resourceServer?.allowedUrls) {
      return !!this.moduleConfig.resourceServer.allowedUrls.find(u => url.toLowerCase().startsWith(u.toLowerCase()));
    }
    return true;
  }
  /**
   * Interceptor for requests.
   * @param req Request to intercept.
   * @param next Http Handler.
   * @returns Intercepted Http Event.
   */
  intercept(req, next) {
    const url = req.url.toLowerCase();
    if (!this.isInterceptedUrl(url)) {
      return next.handle(req);
    }
    const sendAccessToken = this.moduleConfig?.resourceServer?.sendAccessToken ?? false;
    if (!sendAccessToken) {
      // If we don't send the access token return the request, but handle errors
      return next.handle(req).pipe(catchError$1(err => this.errorHandler.handleError(err)));
    }
    // Stores the number of retries
    let retries = 0;
    return this.authService.token$.pipe(map$1(token => {
      // Set authorization header if we have a token
      if (token) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
      }
      return req;
    }), concatMap(authReq => next.handle(authReq)), catchError$1((err, restart) => {
      // If the request is unauthorized, try refreshing the token before restarting.
      if (err.status === 401 && retries === 0) {
        retries++;
        return concat(this.authService.refreshToken$, restart);
      }
      // Handle error
      return this.errorHandler.handleError(err);
    }));
  }
  static {
    this.ɵfac = function AuthInterceptor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AuthInterceptor)(i0.ɵɵinject(AuthService), i0.ɵɵinject(i1.OAuthResourceServerErrorHandler), i0.ɵɵinject(i1.OAuthModuleConfig, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AuthInterceptor,
      factory: AuthInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthInterceptor, [{
    type: Injectable
  }], () => [{
    type: AuthService
  }, {
    type: i1.OAuthResourceServerErrorHandler
  }, {
    type: i1.OAuthModuleConfig,
    decorators: [{
      type: Optional
    }]
  }], null);
})();
function authAppInitializerFactory(authService) {
  return () => authService.runInitialLoginSequence();
}

/**
 * OAuthStorage implementation to store information.
 *
 * This stores sensitive information like access token in memory and not in the
 * session or local storage of the browser.
 */
class AuthStorage extends OAuthStorage {
  constructor() {
    super(...arguments);
    /** Memory storage */
    this.memory = new Map();
    /** Array of keys to store in memory */
    this.memoryKeys = ['access_token', 'access_token_stored_at', 'expires_at', 'refresh_token'];
  }
  getItem(key) {
    if (this.memoryKeys.includes(key)) {
      return this.memory.get(key) ?? null;
    } else {
      return sessionStorage.getItem(key);
    }
  }
  removeItem(key) {
    if (this.memoryKeys.includes(key)) {
      this.memory.delete(key);
    } else {
      sessionStorage.removeItem(key);
    }
  }
  setItem(key, data) {
    if (this.memoryKeys.includes(key)) {
      this.memory.set(key, data);
    } else {
      sessionStorage.setItem(key, data);
    }
  }
}
function storageFactory() {
  return new AuthStorage();
}

/**
 * Provider for the auth configuration.
 * @param config Auth configuration.
 * @param allowedDomains Array of allowed domains.
 * @returns The created environment provider for the auth client.
 */
function provideAuthClient(config, allowedDomains) {
  const authConfig = {
    loginUrl: config.authorizeEndpoint,
    tokenEndpoint: config.tokenEndpoint,
    revocationEndpoint: config.revocationEndpoint,
    redirectUri: config.redirectUri,
    requireHttps: config.requireHttps ?? 'remoteOnly',
    logoutUrl: config.logoutUrl,
    postLogoutRedirectUri: config.postLogoutRedirectUri,
    clientId: config.clientId,
    responseType: 'code',
    scope: config.scope,
    oidc: false,
    requestAccessToken: true
  };
  const moduleConfig = {
    resourceServer: {
      allowedUrls: allowedDomains,
      sendAccessToken: true
    }
  };
  return makeEnvironmentProviders([provideOAuthClient(moduleConfig), {
    provide: APP_INITIALIZER,
    useFactory: authAppInitializerFactory,
    deps: [AuthService],
    multi: true
  }, {
    provide: OAuthStorage,
    useFactory: storageFactory
  }, {
    provide: OAuthResourceServerErrorHandler,
    useClass: AuthErrorHandler,
    deps: [AuthService]
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }, {
    provide: AuthConfig,
    useValue: authConfig
  }]);
}

/*
 * Public API Surface of auth
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AuthGuard, AuthService, provideAuthClient };
