import { Field } from './field.model';
import { FormlyFieldConfig } from '@ngx-formly/core';

export class CheckboxField extends Field {
  public convertToFormlyFieldConfig(): FormlyFieldConfig {
    return {
      key: this.key,
      type: 'checkbox',
      props: {
        label: this.label,
        required: this.required,
        disabled: this.disabled,
      },
    };
  }

  public getModelData(): Record<string, boolean> {
    if (typeof this.value !== 'boolean' && typeof this.value !== 'undefined') {
      throw new Error(`Wrong value type. Expected boolean got ${typeof this.value}.`);
    }
    if (typeof this.recognized_value !== 'boolean') {
      throw new Error(`Wrong value type. Expected boolean got ${typeof this.recognized_value}.`);
    }

    return { [this.key]: this.value ?? this.recognized_value };
  }
}
