import { ConvertedDataStatus, ConvertedDataType, UUID } from '@core/types';
import { FieldGroup, FieldGroupResponse } from '../form-group/field-group.model';

import { FieldRequest } from '../field';
import { RawData } from '@models/raw-data/raw-data.model';

export interface ConvertedDataResponse {
  id: UUID;
  created_at: string;
  type: ConvertedDataType;
  status: ConvertedDataStatus;
  raw_data: RawData;
  preprocessed_content?: string;
  form_groups: FieldGroupResponse[];
}

export interface ConvertedDataRequest {
  fields: FieldRequest[];
}

export class ConvertedData {
  id: UUID;
  created_at: string;
  type: ConvertedDataType;
  status: ConvertedDataStatus;
  raw_data: RawData;
  preprocessed_content?: string;
  field_groups: FieldGroup[];

  constructor(convertedDataResponse: ConvertedDataResponse) {
    this.id = convertedDataResponse.id;
    this.created_at = convertedDataResponse.created_at;
    this.type = convertedDataResponse.type;
    this.status = convertedDataResponse.status;
    this.raw_data = convertedDataResponse.raw_data;
    this.preprocessed_content = convertedDataResponse.preprocessed_content;
    this.field_groups = this.createFieldGroupsFromResponse(convertedDataResponse.form_groups);
  }

  /**
   * Creates field instances of the given field responses.
   * @param fields Field responses to create the instances from.
   * @returns Array of instantiated fields.
   */
  private createFieldGroupsFromResponse(fieldGroups: FieldGroupResponse[]): FieldGroup[] {
    return fieldGroups.map(fieldGroup => {
      return new FieldGroup(fieldGroup);
    });
  }
}
