import { Page, PageRequest } from '@ntrx/pagination';

import { ApiService } from '../api';
import { Article } from '@core/models';
import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  private readonly baseUrl = '/articles';

  constructor(private apiService: ApiService) {}

  /**
   * Get all articles as paginated response.
   * @param request Additional request params.
   * @returns Observable with the paginated response of all articles.
   */
  public page(request?: PageRequest<Article>): Observable<Page<Article>> {
    return this.apiService.get<Page<Article>>(this.baseUrl, { params: request?.toHttpParams() });
  }

  /**
   * Upload and proccess article data.
   * @param files File array to upload.
   * @returns Observable with the current upload progress.
   */
  public importArticleData(files: File[]): Observable<HttpEvent<unknown>> {
    const url = `/import${this.baseUrl}`;
    const formData = new FormData();
    for (const file of files) {
      formData.append('file', file, file.name);
    }

    return this.apiService.post<HttpEvent<unknown>>(url, formData, { reportProgress: true, observe: 'events' });
  }
}
