import { BehaviorSubject, Observable, take, tap } from 'rxjs';

import { ApiService } from '@services/api';
import { Injectable } from '@angular/core';
import { User } from '@models/index';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly baseUrl = '/users';
  private readonly usersMeRoute = `${this.baseUrl}/me`;
  private _currentUser$: BehaviorSubject<User | null | undefined> = new BehaviorSubject<User | null | undefined>(null);

  /** Current user */
  public readonly me$: Observable<User | null | undefined> = this._currentUser$.asObservable();

  constructor(private apiService: ApiService) {
    this.refreshUser();
  }

  /** Init user observable value. */
  private initUser(user: User): void {
    this._currentUser$.next(user);
  }

  /**
   * Get the current authenticated user.
   * @returns Observable with the current authenticated user.
   */
  private getUserMe(): Observable<User> {
    return this.apiService.get<User>(this.usersMeRoute).pipe(
      tap(user => {
        this.initUser(user);
      })
    );
  }

  /** Refresh the current authenticated user. */
  public refreshUser(): void {
    this.getUserMe().pipe(take(1)).subscribe();
  }
}
