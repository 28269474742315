import { Page, PageRequest } from '@ntrx/pagination';

import { ApiService } from '@services/api';
import { Company } from '@models/index';
import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private readonly baseUrl = '/companies';

  constructor(private apiService: ApiService) {}

  /**
   * Get all companies as paginated response.
   * @param request Additional request params.
   * @returns Observable with the paginated response of all companies.
   */
  public page(request?: PageRequest<Company>): Observable<Page<Company>> {
    return this.apiService.get<Page<Company>>(this.baseUrl, { params: request?.toHttpParams() });
  }

  /**
   * Upload and proccess company data.
   * @param files File array to upload.
   * @returns Observable with the current upload progress.
   */
  public importCompanyData(files: File[]): Observable<HttpEvent<unknown>> {
    const url = `/import${this.baseUrl}`;
    const formData = new FormData();
    for (const file of files) {
      formData.append('file', file, file.name);
    }

    return this.apiService.post<HttpEvent<unknown>>(url, formData, { reportProgress: true, observe: 'events' });
  }
}
