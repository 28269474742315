<nav>
  <div class="nav-group-header text-body-secondary">Dokumentenmanagement</div>
  <ul>
    <li>
      <a class="nav-item" routerLink="" routerLinkActive="active-item" [routerLinkActiveOptions]="{ exact: true }">
        Dokumenteneingang
      </a>
    </li>
    <li>
      <a class="nav-item disabled" routerLink="/data-import" routerLinkActive="active-item">
        Datenimport
        <span class="badge rounded-pill text-bg-info d-flex align-items-center ms-2">Coming soon</span>
      </a>
    </li>
  </ul>
</nav>

<div class="bottom-controls">
  <a class="menu-control text-body-secondary" role="button">Ausloggen</a>
</div>
