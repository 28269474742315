import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

import { Multiloader } from '@core/utilities';

/**
 * Directive to put a loading spinner over a button. When the spinner
 * is shown the button interaction will be disabled.
 *
 * @example
 * // Provide a boolean or multiloader indicating if loading is active or not. If loading
 * // is active, the button state will be set to `disabled`.
 * <button type="submit" [dcBtnLoader]="isLoading()">Save</button>
 */
@Directive({
  selector: 'button[dcBtnLoader]',
  standalone: true,
})
export class BtnLoaderDirective implements OnChanges {
  /** Boolean value indicating whether the loading spinner should be displayed or not. */
  @Input() public dcBtnLoader: boolean | Multiloader = false;
  /** Multiloader ID to separate multiloader events. */
  @Input() public loaderId: string | number | undefined = undefined;
  /** Disable button when other IDs are loading. Works only when `loaderId` is set. Default is `true`. */
  @Input() public disableOther = true;

  /** Loader element */
  private loaderElement: HTMLSpanElement | null = null;

  constructor(
    private el: ElementRef<HTMLButtonElement>,
    private renderer: Renderer2
  ) {}

  public ngOnChanges(): void {
    if (!this.loaderElement) {
      // Render loader if not already created
      this.renderLoader();
    }

    const multiloader = this.dcBtnLoader as Multiloader;
    const loader = this.dcBtnLoader as boolean;

    const isLoading = (multiloader.isLoading && multiloader.id === this.loaderId) || loader === true;
    const shouldDisable = multiloader.id !== this.loaderId && multiloader.isLoading;

    this.showLoader(isLoading);
    if (this.disableOther && shouldDisable) {
      this.setDisabled(shouldDisable);
    }
  }

  private renderLoader(): void {
    // Create loader element
    this.renderer.addClass(this.el.nativeElement, 'with-loader');

    const loaderElement = this.renderer.createElement('span') as HTMLSpanElement;
    this.renderer.addClass(loaderElement, 'spinner-border');
    this.renderer.addClass(loaderElement, 'spinner-border-sm');
    this.renderer.addClass(loaderElement, 'ms-2');
    this.renderer.setAttribute(loaderElement, 'role', 'status');
    // Create loader, set classes and attributes
    const loader = this.renderer.createElement('span') as HTMLSpanElement;
    this.renderer.addClass(loader, 'visually-hidden');
    this.renderer.appendChild(loaderElement, loader);

    this.loaderElement = loaderElement;
  }

  /**
   * Show loader element.
   * @param show Boolean whether to show or hide.
   */
  private showLoader(show: boolean): void {
    if (!this.el.nativeElement) {
      return;
    }

    if (show) {
      this.renderer.appendChild(this.el.nativeElement, this.loaderElement);
    } else {
      this.renderer.removeChild(this.el.nativeElement, this.loaderElement);
    }
    this.setDisabled(show);
  }

  /**
   * Set disabled state of button.
   * @param disable Boolean whether disabled or not.
   */
  private setDisabled(disable: boolean): void {
    if (disable) {
      this.renderer.setAttribute(this.el.nativeElement, 'disabled', '');
    } else {
      this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
    }
  }
}
