import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { convertNetworkError } from '@ntrx/error-handler';
import { environment } from '@environments/environment';

export interface HttpOptions {
  headers?: HttpHeaders | Record<string, string | string[]>;
  observe?: 'body' | 'response' | 'events';
  params?: HttpParams | Record<string, string | string[]>;
  reportProgress?: boolean;
  responseType?: 'json' | 'blob';
  withCredentials?: boolean;
  apiVersion?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  /**
   * Constructs a `GET` request that interpretes the body as a JSON object and returns the reponse body in a given type.
   * @param path Path to send the `GET` request to.
   * @param options Additional options to specify the request.
   * @returns Observable with the response body in a given type.
   */
  public get<T = unknown>(path: string, options?: HttpOptions): Observable<T> {
    return this.http
      .get<T>(this.constructUrl(path, options?.apiVersion), options as object)
      .pipe(convertNetworkError());
  }

  /**
   * Constructs a `POST` request that interprets the body as a JSON object and returns the response body in a given type.
   * @param path Path to send the `POST` request to.
   * @param body Body payload to send.
   * @param options Additional options to specify the request.
   * @returns Observable with the response body in a given type.
   */
  public post<T = unknown>(path: string, body: unknown, options?: HttpOptions): Observable<T> {
    return this.http
      .post<T>(this.constructUrl(path, options?.apiVersion), body, options as object)
      .pipe(convertNetworkError());
  }

  /**
   * Constructs a `PATCH` request that interprets the body as a JSON object and returns the response body in a given type.
   * @param path Path to send the `PATCH` request to.
   * @param body Body payload to send.
   * @param options
   * @returns
   */
  public patch<T = unknown>(path: string, body: unknown, options?: HttpOptions): Observable<T> {
    return this.http
      .patch<T>(this.constructUrl(path, options?.apiVersion), body, options as object)
      .pipe(convertNetworkError());
  }

  /**
   * Constructs a `DELETE` request that interprets the body as a JSON object and returns the response body in a given type.
   * @param path Path to send the `DELETE` request to.
   * @param options
   * @returns
   */
  public delete<T = unknown>(path: string, options?: HttpOptions): Observable<T> {
    return this.http
      .delete<T>(this.constructUrl(path, options?.apiVersion), options as object)
      .pipe(convertNetworkError());
  }

  /**
   * Construct complete api url.
   * @param path Path which should be appended to the api base url.
   * @param apiVersion Version string of the api - default configuration in environment.
   * @returns Complete api url string.
   */
  private constructUrl(path: string, apiVersion: string = environment.apiVersion): string {
    if (!path.startsWith('/')) {
      throw new Error('Path should start with a leading slash "/"');
    }
    if (environment.apiBaseURL.endsWith('/')) {
      throw new Error('API URL should not contain a trailing slash "/"');
    }

    return `${environment.apiBaseURL}/${apiVersion}${path}`;
  }
}
