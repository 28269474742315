import { Channel, ConvertedData, ConvertedDataRequest, ConvertedDataResponse } from '@models/index';
import { Observable, map } from 'rxjs';
import { Page, PageRequest } from '@ntrx/pagination';

import { ApiService } from '@services/api/api.service';
import { Injectable } from '@angular/core';
import { UUID } from '@core/types';

@Injectable({
  providedIn: 'root',
})
export class ConvertedDataService {
  private readonly endpoint = '/converted_data';

  constructor(private apiService: ApiService) {}

  /**
   * Get all converted data as paginated response.
   * @param request Additional data for the paginated request.
   * @returns Observable with paginated response of all converted data.
   */
  public page(request: PageRequest<ConvertedData>): Observable<Page<ConvertedData>> {
    return this.apiService.get<Page<ConvertedData>>(this.endpoint, { params: request.toHttpParams() });
  }

  /**
   * Get a single existing converted data by given id.
   * @param id Id of the converted data to fetch.
   * @returns Oberservable with the converted data.
   */
  public getConvertedData(id: UUID): Observable<ConvertedData> {
    return this.apiService
      .get<ConvertedDataResponse>(`${this.endpoint}/${id}`)
      .pipe(map(response => new ConvertedData(response)));
  }

  /**
   * Get all converted data channels.
   * @returns Observable with the converted data channels.
   */
  public getChannels(): Observable<Channel[]> {
    return this.apiService.get<Channel[]>(`${this.endpoint}/channels`);
  }

  /**
   * Update existing converted data by given id.
   * @param id Id of the converted data to update.
   * @param requestData Requets data to update the converted data with.
   * @returns Observable with the updated converted data.
   */
  public patchConvertedData(id: UUID, requestData: ConvertedDataRequest): Observable<ConvertedData> {
    return this.apiService.patch<ConvertedData>(`${this.endpoint}/${id}`, requestData);
  }
}
