import { AccountComponent } from '@components/account/account.component';
import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { RouterModule } from '@angular/router';
import { User } from '@models/index';
import { UserService } from '@services/index';

@Component({
  selector: 'dc-top-navigation',
  standalone: true,
  imports: [RouterModule, AccountComponent, AsyncPipe],
  templateUrl: './top-navigation.component.html',
  styleUrl: './top-navigation.component.scss',
})
export class TopNavigationComponent {
  protected currentUser: Observable<User | undefined | null> | undefined;

  constructor(private userService: UserService) {
    this.currentUser = this.userService.me$;
  }
}
