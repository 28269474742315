<div class="modal-header">
  <h1>{{ title }}</h1>
</div>
<div class="modal-body">
  <p>{{ message }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="cancel()">
    {{ cancelButtonText }}
  </button>
  <button type="button" class="btn btn-primary" (click)="confirm()">
    {{ confirmButtonText }}
  </button>
</div>
