import { ApiService } from '../api';
import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RawDataUploadBody } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class RawDataService {
  private readonly endpoint = '/raw_data';

  constructor(private apiService: ApiService) {}

  /**
   * Manual upload for raw data.
   * @param body Raw data to upload. Contains a title and an array of files.
   * @returns Observable with the current upload progress / HttpEvent.
   */
  public uplaodRawData(body: RawDataUploadBody): Observable<HttpEvent<unknown>> {
    const formData = new FormData();
    formData.append('title', body.title);
    for (const file of body.files) {
      formData.append('files[]', file, file.name);
    }

    return this.apiService.post<HttpEvent<unknown>>(this.endpoint, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }
}
