<header class="d-flex justify-content-between">
  <div class="d-flex align-items-center gap-3">
    @if (enableBackNavigation) {
      <a routerLink="/{{ parentRoute }}" role="button" class="btn btn-flat">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>
      </a>
    }
    <ng-content select="[title]" />
  </div>
  <ng-content select="[rightSide]" />
</header>
