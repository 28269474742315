import { AppError, ErrorModalProvider, Message, NetworkError } from '@ntrx/error-handler';
import { ConfirmModal, ErrorModal } from '@components/index';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalService extends ErrorModalProvider {
  constructor(private modalService: NgbModal) {
    super();
  }
  // eslint-disable-next-line
  public confirm<T = any>(
    title: string,
    message: string,
    options?: { confirmButtonText?: string; cancelButtonText?: string }
  ): Promise<T> {
    const modalRef = this.modalService.open(ConfirmModal, { centered: true, size: 'md' });
    const componentInstance = modalRef.componentInstance as ConfirmModal;
    componentInstance.title = title;
    componentInstance.message = message;

    if (options?.confirmButtonText) {
      componentInstance.confirmButtonText = options.confirmButtonText;
    }
    if (options?.cancelButtonText) {
      componentInstance.cancelButtonText = options.cancelButtonText;
    }

    return modalRef.result;
  }

  // eslint-disable-next-line
  public alert<T = any>(
    title: string,
    message: string,
    options?: { buttonText?: string; errorMessage?: string; details?: string[] | null }
  ): Promise<T> {
    const modalRef = this.modalService.open(ErrorModal, { centered: true, size: 'md' });
    const componentInstance = modalRef.componentInstance as ErrorModal;
    componentInstance.title = title;
    componentInstance.message = message;

    if (options?.buttonText) {
      componentInstance.buttonText = options.buttonText;
    }

    if (options?.details) {
      componentInstance.details = options.details;
    }

    return modalRef.result;
  }

  public async appError(error: AppError): Promise<boolean> {
    return this.alert(error.title, error.message);
  }

  public async networkError(message: Message, error: NetworkError): Promise<boolean> {
    return this.alert(message.title, message.body, { details: error.details });
  }

  /**
   * Open custom modal.
   * @param modal Modal component to open.
   * @param options NgbModal options.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public custom<T = any>(modal: any, options?: NgbModalOptions): Promise<T> {
    const modalRef = this.modalService.open(modal, options);
    return modalRef.result;
  }
}
