import { Field } from './field.model';
import { FormlyFieldConfig } from '@ngx-formly/core';

export class SelectField<T> extends Field<T> {
  public convertToFormlyFieldConfig(): FormlyFieldConfig {
    return {
      key: this.key,
      type: 'select',
      props: {
        label: this.label,
        required: this.required,
        disabled: this.disabled,
        options: this.options,
      },
    };
  }

  public getModelData(): Record<string, T | undefined> {
    return { [this.key]: this.value ?? this.recognized_value };
  }
}
