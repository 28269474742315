<div class="modal-header">
  <h1>{{ title }}</h1>
</div>
<div class="modal-body">
  <p class="m-0">{{ message }}</p>
  @if (details) {
    <div class="error-details">
      @for (detail of details; track $index) {
        <p class="m-0">{{ detail }}</p>
      }
    </div>
  }
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="confirm()">
    {{ buttonText }}
  </button>
</div>
