import { ActivatedRoute, RouterLink } from '@angular/router';
import { Component, Input, booleanAttribute } from '@angular/core';

@Component({
  selector: 'dc-page-header',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.scss',
})
export class PageHeaderComponent {
  @Input({ transform: booleanAttribute }) public enableBackNavigation = false;

  protected readonly parentRoute: string | undefined;

  constructor(private activeRoute: ActivatedRoute) {
    if (activeRoute.parent) {
      this.parentRoute = activeRoute.parent.snapshot.url[0].path;
    }
  }
}
