import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dc-confirm',
  standalone: true,
  imports: [],
  templateUrl: './confirm.modal.html',
  styleUrl: './confirm.modal.scss',
})
export class ConfirmModal {
  @Input({ required: true }) public title = '';
  @Input({ required: true }) public message = '';
  @Input() public confirmButtonText = 'Ok';
  @Input() public cancelButtonText = 'Abbrechen';

  constructor(private activeModal: NgbActiveModal) {}

  protected confirm(): void {
    this.activeModal.close(true);
  }

  protected cancel(): void {
    this.activeModal.dismiss(false);
  }
}
