import { Component, Input } from '@angular/core';

import { AuthService } from '@ntrx/auth';
import { FullnamePipe } from '@core/pipes';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { User } from '@models/index';

@Component({
  selector: 'dc-account',
  standalone: true,
  imports: [NgbDropdownModule, FullnamePipe],
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss',
})
export class AccountComponent {
  @Input({ required: true }) public currentUser: User | undefined | null;

  constructor(private authService: AuthService) {}

  protected logout(): void {
    this.authService.logout();
  }
}
