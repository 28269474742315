import { catchError, of, EMPTY, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import * as i0 from '@angular/core';
import { InjectionToken, inject, Injectable, Inject, makeEnvironmentProviders, ErrorHandler } from '@angular/core';
class AppError extends Error {
  /**
   * Creates a new `AppError` instance.
   * @param error Error
   * @param message Optional message that will be used as title and message.
   */
  constructor(error, message) {
    const errorMessage = message?.body ?? error?.message ?? 'Unknown Error';
    super(errorMessage);
    this.name = 'AppError';
    this.title = message?.title ?? 'Fehler';
    this.underlyingError = error;
  }
  /**
   * Creates a new `AppError` from an existing error.
   * @param error Error that occured.
   * @param message Optional message to set title and message.
   * @returns `AppError` for the given error.
   */
  static fromError(error, message) {
    return new AppError(error, message);
  }
  /**
   * Creates a new `AppError` from a message and an optional title.
   * @param message Message for the error.
   * @param title Optional title for the error.
   * @returns `AppError` with the given message.
   */
  static fromMessage(message, title) {
    return new AppError(undefined, {
      title,
      body: message
    });
  }
}
/**
 * Checks id the given error is an `AppError`.
 * @param error Error to check.
 * @returns `true` if the error is an instance of `AppError`.
 */
function isAppError(error) {
  return error instanceof AppError;
}
class NetworkError extends AppError {
  constructor(response) {
    super(response);
    this.name = 'NetworkError';
    /** Details from the error. */
    this.details = null;
    this.underlyingError = response;
    this.status = response.status;
    if (response.error) {
      this.details = this.parseErrorDetails(response.error);
    }
  }
  /**
   * Parse client error from error response.
   * @param defaultMessage Default error message if no client error message can be parsed.
   * @returns Parsed client error as `Message`.
   */
  parseClientError(defaultMessage) {
    // If error message is not 'validation failed' use it as message.
    const symfonyMessage = this.underlyingError?.error?.message?.trim()?.toLowerCase() !== 'validation failed' ? this.underlyingError?.error?.message : null;
    // First error message from response.
    const errorMessage = this.underlyingError?.error?.error?.message;
    // Details from RFC error message.
    const details = this.underlyingError?.error?.detail;
    return {
      title: defaultMessage.title,
      body: symfonyMessage ?? errorMessage ?? details ?? defaultMessage.body
    };
  }
  /**
   * Parse error details from error response.
   * @param error Error response from backend.
   * @returns Error details as string array or `null`.
   */
  parseErrorDetails(error) {
    const errorMessages = new Set();
    // Symfony errors that are not mapped to a children
    if (error?.errors?.errors && Array.isArray(error?.errors.errors)) {
      error.errors.errors.forEach(message => errorMessages.add(message));
    }
    // Default symfony error format (children)
    if (error?.errors?.children) {
      const childErrors = this.parseChildrenError(error.errors.children);
      childErrors.forEach(error => errorMessages.add(error));
    }
    if (errorMessages.size === 0) {
      return null;
    }
    return Array.from(errorMessages);
  }
  /**
   * Parse error messages from children.
   * @param children Error children object.
   * @returns Array of error messages.
   */
  parseChildrenError(children) {
    const messages = [];
    for (const child in children) {
      // parse normal error messages
      if (children[child]?.errors) {
        children[child].errors?.forEach(error => messages.push(error));
      }
      // check if children has another children
      if (children[child]?.children) {
        const nestedChildren = children[child].children;
        if (!nestedChildren) {
          continue;
        }
        if (Array.isArray(nestedChildren)) {
          // children can be an array with another children
          for (const nestedChild of nestedChildren) {
            if (nestedChild.children) {
              messages.push(...this.parseChildrenError(nestedChild.children));
            }
          }
        } else {
          messages.push(...this.parseChildrenError(nestedChildren));
        }
      }
    }
    return messages;
  }
}
/**
 * Checks if the given error is an network error.
 * @param error Error to check.
 * @returns `true` if the error is an instance of network error.
 */
function isNetworkError(error) {
  return error instanceof NetworkError;
}

/**
 * Operator that catches an `AppError`.
 * @param modalProvider Modal provider that will be called with the `AppError`.
 * @param returnValue Optional return value. If no value is provided the operator will return an empty observable which
 * emits the complete notification.
 * @returns
 */
function catchAppError(modalProvider, returnValue) {
  return source => source.pipe(catchError(error => {
    if (error instanceof AppError) {
      modalProvider.handleAppError(error);
      return returnValue ? of(returnValue) : EMPTY;
    } else {
      return throwError(() => error);
    }
  }));
}

/**
 * Operator that catches a `NetworkError`.
 * @param modalProvider Modal provider that will be called with the `NetworkError`.
 * @param returnValue Optional return value. If no value is provided the operator will return an
 * empty observable which emits the complete notification.
 */
function catchNetworkError(modalProvider, returnValue) {
  return source => source.pipe(catchError(error => {
    if (error instanceof NetworkError) {
      modalProvider.handleAppError(error);
      return returnValue ? of(returnValue) : EMPTY;
    } else {
      return throwError(() => error);
    }
  }));
}

/**
 * Operator that converts a `HttpErrorResponse` to a `NetworkError`.
 * @returns Observable that throws a `NetworkError` when a `HttpErrorResponse` is thrown.
 */
function convertNetworkError() {
  return source => source.pipe(catchError(error => {
    if (error instanceof HttpErrorResponse) {
      return throwError(() => new NetworkError(error));
    } else {
      return throwError(() => error);
    }
  }));
}

/**
 * Default error messages for HTTP status codes.
 */
const DEFAULT_ERROR_MESSAGES = {
  0: {
    title: 'Fehler',
    body: 'Es kam zu einem Fehler bei der Anfrage. Prüfen Sie die Verbindung und versuchen Sie es erneut.'
  },
  400: {
    title: 'Fehlerhafte Anfrage',
    body: 'Die Anfrage konnte aufgrund von fehlerhaften Daten nicht verarbeitet werden.'
  },
  401: {
    title: 'Sitzung abgelaufen',
    body: 'Ihre Sitzung ist abgelaufen, bitte loggen Sie sich erneut ein.'
  },
  403: {
    title: 'Zugriff verweigert',
    body: 'Sie haben keine Berechtigung für diese Aktion.'
  },
  404: {
    title: 'Nicht gefunden',
    body: 'Die angeforderte Resource konnte nicht gefunden werden.'
  },
  500: {
    title: 'Internal Server Error',
    body: 'Bei der Anfrage kam es zu einem internen Serverfehler.'
  },
  502: {
    title: 'Bad Gateway',
    body: 'Bei der Anfrage kam es zu einem Bad Gateway Serverfehler. Bitte versuchen Sie es später erneut.'
  },
  503: {
    title: 'Service Unavailable',
    body: 'Der Dienst ist aktuell nicht verfügbar. Bitte versuchen Sie es später erneut.'
  },
  default: {
    title: 'Fehler',
    body: 'Bei der Anfrage kam es zu einem unbekannten Fehler.'
  }
};
const ERROR_CONFIG = new InjectionToken('ErrorConfig');
/** Default error handler configuration. */
const DEFAULT_CONFIG = {
  enableSentry: false,
  reloadAtChunkLoadingError: true,
  customReloadFunction: window.location.reload,
  preventLoggingInConsole: false,
  ignoredNetworkErrors: [401]
};
class ErrorModalProvider {
  constructor() {
    /** Error configuration. */
    this.config = inject(ERROR_CONFIG);
    /** Array of currently shown errors. */
    this.currentlyShownAppErrors = [];
  }
  /**
   * Creates a formatted error message with a title and body that can be shown
   * to the user.
   * @returns Formatted error response that can be shown to the user.
   */
  formattedErrorMessage(error) {
    switch (error.status) {
      // Generic error
      case 0:
        return this.config.errorMessages?.[0] ?? DEFAULT_ERROR_MESSAGES[0];
      // Server errors
      case 500:
        return this.config.errorMessages?.[500] ?? DEFAULT_ERROR_MESSAGES[500];
      case 502:
        return this.config.errorMessages?.[502] ?? DEFAULT_ERROR_MESSAGES[502];
      case 503:
        return this.config.errorMessages?.[503] ?? DEFAULT_ERROR_MESSAGES[503];
      // Client errors
      case 400:
        return error.parseClientError(this.config.errorMessages?.[400] ?? DEFAULT_ERROR_MESSAGES[400]);
      case 401:
        return this.config.errorMessages?.[401] ?? DEFAULT_ERROR_MESSAGES[401];
      case 403:
        return this.config.errorMessages?.[403] ?? DEFAULT_ERROR_MESSAGES[403];
      case 404:
        return this.config.errorMessages?.[404] ?? DEFAULT_ERROR_MESSAGES[404];
      // Unknown error
      default:
        return this.config.errorMessages?.default ?? DEFAULT_ERROR_MESSAGES.default;
    }
  }
  /**
   * Handle `AppError` and `NetworkError` by calling the appropriate
   * `appError()` or `networkError()` methods.
   *
   * If an `AppError` is displayed and this method is called with the same
   * error again, it won't display the error, until the old modal is closed.
   * @param error Error to handle.
   * @returns Boolean promise, resolves when the modal is closed or dismissed.
   * `true` when the user clicks the `OK` button, otherwise `false`.
   */
  async handleAppError(error) {
    if (this.isErrorShowing(error)) {
      return true;
    }
    try {
      this.currentlyShownAppErrors.push(error);
      return await this.showErrorModal(error);
    } catch {
      return false;
    } finally {
      // Remove network error when modal is closed
      const index = this.indexOfAppError(error);
      if (index >= 0) {
        this.currentlyShownAppErrors.splice(index, 1);
      }
    }
  }
  /**
   * Show error modal for the given error.
   * @param error Error to show.
   * @returns Boolean promise, resolves when the modal is closed or dismissed.
   * `true` when the user clicks the `OK` button, otherwise `false` or when no
   * error was shown.
   */
  async showErrorModal(error) {
    if (isNetworkError(error)) {
      if (this.isIgnoredNetworkError(error)) {
        return false;
      }
      return await this.networkError(this.formattedErrorMessage(error), error);
    }
    if (isAppError(error)) {
      return await this.appError(error);
    }
    return false;
  }
  /**
   * Checks if the given network error is an ignored status code.
   * @param error Error to check.
   * @returns Returns `true`, when the error should be ignored. Otherwise `false`.
   */
  isIgnoredNetworkError(error) {
    return this.config.ignoredNetworkErrors.includes(error?.status);
  }
  /**
   * Check if an error is already shown.
   * @param error Error to check.
   * @returns `true` if the error is already shown.
   */
  isErrorShowing(error) {
    if (error instanceof AppError) {
      return this.indexOfAppError(error) >= 0;
    }
    return false;
  }
  /**
   * Get the index of an app error.
   * @param error Error to get the index from.
   * @returns Index of the app error or -1 if not found.
   */
  indexOfAppError(error) {
    return this.currentlyShownAppErrors.findIndex(current => {
      return current.title === error.title && current.message === error.message && current.name === error.name;
    });
  }
}
class SentryProvider {}

/**
 * `AppErrorHandler` for handling custom app errors and incoming backend errors.
 *
 * This Class can handle errors that are instances of `AppError` and `NetworkError`.
 */
class AppErrorHandler {
  constructor(injector, zone, config) {
    this.injector = injector;
    this.zone = zone;
    this.config = config;
    // Add listener
    window.addEventListener('vite:preloadError', () => {
      this.reload();
      return;
    });
  }
  handleError(error) {
    // Handels chunk loading errors.
    if (this.config.reloadAtChunkLoadingError) {
      this.handleChunkLoadingError(error);
    }
    // Log errors in sentry if sentry is enabled.
    if (this.config.enableSentry) {
      const sentry = this.injector.get(SentryProvider, null);
      if (!sentry) {
        console.warn('Provide a implementation for the `SentryProvider` when enabling Sentry.');
      }
      sentry?.captureExceptionInSentry(error);
    }
    // Log error in browser console.
    if (!this.config.preventLoggingInConsole) {
      console.error(error);
    }
    // Handle only `AppError` errors
    if (!isAppError(error)) {
      return;
    }
    if (isAppError(error)) {
      this.zone.run(() => {
        this.injector.get(ErrorModalProvider).handleAppError(error);
        return;
      });
    }
  }
  /**
   * Handles a chunk loading error.
   *
   * If the Vite build tool is used, a listeners on `vite:preloadError` is set.
   * Otherwise the error message is checked by a regular expression.
   * @param error
   * @returns
   */
  handleChunkLoadingError(error) {
    // Test for chunk loading error and reload
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error?.message)) {
      this.reload();
      return;
    }
  }
  /**
   * Handles the reload of the application.
   *
   * If no custom reload funciton is configured the window reloads.
   */
  reload() {
    this.config.customReloadFunction();
  }
  static {
    this.ɵfac = function AppErrorHandler_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppErrorHandler)(i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(ERROR_CONFIG));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AppErrorHandler,
      factory: AppErrorHandler.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppErrorHandler, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i0.Injector
  }, {
    type: i0.NgZone
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [ERROR_CONFIG]
    }]
  }], null);
})();

/**
 * Modal provider that uses the `window.alert()` method to show errors.
 */
class AlertErrorProvider extends ErrorModalProvider {
  /**
   * @inheritdoc
   */
  appError(error) {
    alert(error.message);
    return Promise.resolve(true);
  }
  /**
   * @inheritdoc
   */
  networkError(message) {
    alert(message.body);
    return Promise.resolve(true);
  }
}

/**
 * Provider for the error handler configuration.
 *
 * Provides a `ErrorModalProvider` that uses the `window.alert()` method to
 * show errors. Provide a custom implementation of the `ErrorModalProvider` to
 * customize the error display.
 * @example
 * ```ts
 * export const appConfig: ApplicationConfig = {
 *  providers: [
 *    // Provide error handler
 *    provideErrorHandler(...)
 *    // Provide custom error modal provider
 *    { provide: ErrorModalProvider, useClass: CustomErrorProvider }
 *  ]
 * }
 * ```
 * @param config Error handler configuration.
 * @returns The created environment provider for the error handler.
 */
function provideErrorHandler(config) {
  const errorConfig = {
    enableSentry: config?.enableSentry ?? DEFAULT_CONFIG.enableSentry,
    preventLoggingInConsole: config?.preventLoggingInConsole ?? DEFAULT_CONFIG.preventLoggingInConsole,
    reloadAtChunkLoadingError: config?.reloadAtChunkLoadingError ?? DEFAULT_CONFIG.reloadAtChunkLoadingError,
    customReloadFunction: config?.customReloadFunction ?? DEFAULT_CONFIG.customReloadFunction,
    ignoredNetworkErrors: config?.ignoredNetworkErrors ?? DEFAULT_CONFIG.ignoredNetworkErrors,
    errorMessages: config?.errorMessages
  };
  return makeEnvironmentProviders([{
    provide: ERROR_CONFIG,
    useValue: errorConfig
  }, {
    provide: ErrorHandler,
    useClass: AppErrorHandler
  }, {
    provide: ErrorModalProvider,
    useClass: AlertErrorProvider
  }]);
}

/*
 * Public API Surface of app-error-handler
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AppError, AppErrorHandler, ERROR_CONFIG, ErrorModalProvider, NetworkError, SentryProvider, catchAppError, catchNetworkError, convertNetworkError, isAppError, isNetworkError, provideErrorHandler };
