import { Pipe, PipeTransform } from '@angular/core';

import { User } from '@models/index';

@Pipe({
  name: 'fullname',
  standalone: true,
})
export class FullnamePipe implements PipeTransform {
  transform(user: User | undefined | null): string {
    if (user) {
      return `${user.first_name} ${user.last_name}`;
    }

    return 'Unknown';
  }
}
